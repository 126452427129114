import { DrawerBody, DrawerFooter, Stack, Text, Flex } from "@chakra-ui/core";
import React from "react";
import api from "~/cart/api";
import ArrowLeftIcon from "@pency/components/icons/ArrowLeft";
import CrossIcon from "@pency/components/icons/Cross";
import { Field } from "~/tenant/types";
import CheckoutButton from "./CheckoutButton";
import { useTranslation } from "~/i18n/hooks";
import { usePrice } from "~/i18n/hooks";
import FieldsForm from "../../forms/FieldsForm";
import CheckoutBody from "./Components/CheckoutBody";
import {
  addFinancialCost,
  getCost,
  getTotal,
  hasDelivery,
} from "~/cart/selectors";
import { CartItem } from "~/cart/types";
import { useTenant } from "~/tenant/hooks";
import { paymentStatusConstants } from "@pency/api/orders/controllers/patch/types";

interface Props {
  onSubmit?: (fields: Field[], formValues, total, getTotalWithDelivery) => void;
  onClose?: VoidFunction;
  onPrevious?: () => void;
  order?: any;
  fields: Field[];
  items: CartItem[];
  pageId?: string
}

const Checkout: React.FC<Props> = ({
  onSubmit,
  onPrevious,
  onClose,
  items,
  fields,
  order,
  pageId = 'store_page'
}) => {
  const [isLoading, toggleLoading] = React.useState(false);
  const t = useTranslation();
  const p = usePrice();
  const tenant = useTenant()

  function handleSubmit(event: React.MouseEvent, submit: any) {
    // Function to handle message in order page
    if (pageId !== 'store_page') {
      // We format prices to create message
      const mapPrices = {
        ...order.priceVar,
        cost: order.totalPrice
      }
      // checkout function that create the message
      const message = api.checkout(
        {
          phone: tenant.phone, 
          items: order.items, 
          orderId: order._id, 
          t, 
          mapPrices, 
          state: paymentStatusConstants[order.paymentStatus]?.label, 
          fields: order.fields
        }
      )
      let tab = window.open("", "_blank") || window;
      // redirect user to whatsapp      
      return tab.location.href = message
    }

    event.stopPropagation();

    toggleLoading(true);

    submit().finally(() => toggleLoading(false));
  }

  const total = getTotal(items);

  const getTotalWithDelivery = (formValues): number => {
    const delivery = hasDelivery(formValues, t, fields);
    const deliveryCost = getCost(fields);
    const { financialCost } = addFinancialCost(formValues, total);
    let cost = total;

    if (financialCost) {
      cost = financialCost;
    }

    if (delivery) {
      cost = cost + deliveryCost;
    }

    return cost;
  };

  return (
    <FieldsForm
      defaultValues={fields}
      onSubmit={onSubmit}
      total={total}
      getTotalWithDelivery={getTotalWithDelivery}
    >
      {() => (
        <>
          <DrawerBody overflowY="auto" paddingX={{base: "1rem", md: '3rem'}}>
            {pageId === 'store_page' && (
              <ArrowLeftIcon
                background="white"
                boxShadow="md"
                cursor="pointer"
                left={0}
                marginTop={4}
                paddingX={4}
                paddingY={3}
                position="absolute"
                roundedRight="lg"
                top={0}
                onClick={onPrevious}
              />
            )}
            <CrossIcon
              background="white"
              boxShadow="md"
              cursor="pointer"
              marginTop={4}
              paddingX={4}
              paddingY={3}
              position="absolute"
              right={0}
              roundedLeft="lg"
              top={0}
              onClick={onClose}
            />
            <Stack marginTop={20} spacing={6}>
              <CheckoutBody order={order} />
            </Stack>
          </DrawerBody>
          <DrawerFooter
            borderTopColor="gray.100"
            borderTopWidth={1}
            marginTop={2}
            paddingX={{base: "1rem", md: '3rem'}}
          >
            <Stack spacing={4} width="100%">
              {order?.priceVar?.financial > 0 && (
                <Flex
                  alignItems="center"
                  fontSize="lg"
                  fontWeight={500}
                  justifyContent="space-between"
                >
                  <Text>{order.priceVar.opt}</Text>
                  <Text>{p(order.priceVar.financial)}</Text>
                </Flex>
              )}
              {order?.priceVar?.delivery > 0 && (
                <Flex
                  alignItems="center"
                  fontSize="lg"
                  fontWeight={500}
                  justifyContent="space-between"
                >
                  <Text>{"Envio"}</Text>
                  <Text>{p(order.priceVar.delivery)}</Text>
                </Flex>
              )}
              <Flex
                alignItems="center"
                fontSize="lg"
                fontWeight={500}
                justifyContent="space-between"
              >
                <Text>{t("cart.estimatedTotal")}</Text>
                <Text>{p(order.totalPrice)}</Text>
              </Flex>
              <CheckoutButton
                isLoading={isLoading}
                onClick={(event) => handleSubmit(event, onSubmit)}
              />
            </Stack>
          </DrawerFooter>
        </>
      )}
    </FieldsForm>
  );
};

export default Checkout;
